import React, { useState, useEffect } from "react";
const imageList = require("../restaurant_data.json");
console.log(imageList);

export default function RestaurantPage() {
  return (
    <div className="text-white">
      <Introduction />
      <ServiceOverview />
      <Showcase />
      <Menu />
    </div>
  );
}

function Introduction() {
  return (
    <div
      className="relative h-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}${imageList[16]})`,
      }}
    >
      <div className="absolute bottom-0 left-0 right-0 top-0 bg-black opacity-45"></div>
      <div className="absolute right-0 w-52 p-4 font-serif text-xl">
        KELVIAN HOTEL RESTAURANT
      </div>
      <div className="relative pt-40 font-serif text-6xl md:w-3/4 md:pl-16 md:pt-28 md:text-9xl">
        Farm fresh, Organic and Tantalizing Menu
      </div>
      <div className="relative p-4 text-xl md:pl-16 md:pt-8">
        A delectable fusion of African cuisine with international tastes to
        tantalize your every taste bud.
      </div>
      <div className="relative mt-8 text-center md:absolute md:bottom-24 md:right-36">
        <a
          className="p-4"
          style={{ backgroundColor: "#988778" }}
          href="#cuisines"
        >
          VIEW MENU
        </a>
      </div>
    </div>
  );
}

function ServiceOverview() {
  return (
    <div className="h-screen bg-green-950 md:grid md:h-fit md:grid-cols-2 md:gap-2">
      <div className="p-2 md:p-10">
        <img
          className="rounded"
          src={`${process.env.PUBLIC_URL}/assets/services_icon.webp`}
          alt="services"
        />
      </div>
      <div>
        <div className="p-2 text-center text-5xl">Our services</div>
        <div className="ml-auto mr-auto w-fit pt-8">
          <div className="pt-10" style={{ fontSize: "20px" }}>
            Dine at our restaurant
          </div>
          <div className="pt-10" style={{ fontSize: "20px" }}>
            Wedding Catering & Photoshoots
          </div>
          <div className="pt-10" style={{ fontSize: "20px" }}>
            Corporate & Private Catering
          </div>
        </div>
      </div>
    </div>
  );
}

function Showcase() {
  return (
    <div
      className="relative h-screen bg-cover bg-center bg-no-repeat text-center"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/wine_and_dine.webp)`,
      }}
    >
      <div className="absolute bottom-0 left-0 right-0 top-0 bg-black opacity-45"></div>
      <div className="relative pt-36">
        <div className="pt-28 md:pt-0">
          <img
            className="ml-auto mr-auto w-48"
            src={`${process.env.PUBLIC_URL}/assets/wave.svg`}
            alt="wave.svg"
          />
        </div>
        <div className="pt-12 font-serif text-3xl md:ml-auto md:mr-auto md:w-1/2 md:text-5xl">
          We prepare and serve timeless, high-quality dishes in a
          family-friendly atmosphere.
        </div>
      </div>
    </div>
  );
}

function Menu() {
  const [currentImages, setCurrentImages] = useState(imageList.slice(0, 3));
  const [fadeType, setFadeType] = useState("fade-in");

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      setFadeType("fade-out");
      setTimeout(() => {
        currentIndex = (currentIndex + 3) % imageList.length;
        setCurrentImages(imageList.slice(currentIndex, currentIndex + 3));
        setFadeType("fade-in");
      }, 2000);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="relative h-fit bg-green-950 bg-cover bg-center bg-no-repeat"
      /* style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/restaurant_contact_us.webp)`,
      }} */
    >
      <div id="cuisines" className="p-8 text-center text-5xl">
        Our Delicacies
      </div>

      <div className="grid grid-cols-1 justify-items-center md:grid-cols-3">
        {currentImages.map((item, index) => (
          <div className="cuisineCard w-80 md:w-96" key={index}>
            <img
              className={`h-96 w-full rounded object-fill ${fadeType}`}
              src={`${process.env.PUBLIC_URL}${item}`}
              alt={item}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
