const images = require("../gallery_data.json");

export default function GalleryPage() {
  return (
    <div className="w-full">
      {images.length > 0 &&
        images.map((photo) => (
          <div>
            <h2 className="divider donotcross text-4xl">{photo.Name}</h2>
            <div className="h-full w-full">
              <img
                className="h-full w-full object-fill"
                src={`${process.env.PUBLIC_URL}${photo.Image}`}
                alt={`${photo.Name}`}
              />
            </div>
          </div>
        ))}
    </div>
  );
}
