import { useState, useEffect } from "react";
const rooms = require("../room_data.json");

export default function RoomBookingPage({ setShowFooter }) {
  // eslint-disable-next-line
  const [displayedRoom, setDisplayedRoom] = useState(rooms);
  const [roomId, setRoomId] = useState(0);
  const [showImages, setShowImages] = useState(false);
  const [imageId, setImageId] = useState(0);
  const [leftImageId, setLeftImageId] = useState(rooms.length - 1);
  const [rightImageId, setRightImageId] = useState(1);

  useEffect(() => {
    setShowImages(false);
  }, []);

  if (showImages) {
    return (
      <div id="myModal" className="fixed left-0 top-0 z-10 h-full w-full pt-28">
        <div className="text-center">
          <div className="font-serif font-bold" style={{ fontSize: "24px" }}>
            KELVIAN HOTEL
          </div>
          <div>Luxury | Business | Serenity</div>
        </div>
        <button
          onClick={() => {
            setShowImages(false);
            setShowFooter("block");
          }}
          className="absolute right-9 top-4 text-5xl font-bold text-gray-500"
        >
          &times;
        </button>

        <img
          src={displayedRoom[roomId].images[imageId]}
          className="m-auto block h-80 pt-10 md:relative md:top-0 md:w-4/5 md:max-w-2xl md:pt-0"
          alt="Modal"
        />

        <button
          className="absolute top-80 w-auto cursor-pointer select-none p-4 text-lg font-bold text-gray-500 md:top-1/2 md:text-gray-500 md:hover:bg-black md:hover:bg-opacity-40 md:hover:text-white"
          onClick={() => {
            if (imageId > 0) {
              setImageId((prev) => prev - 1);
            }
          }}
        >
          &#10094;
        </button>
        <button
          className="absolute right-0 top-80 w-auto cursor-pointer select-none p-4 text-lg font-bold text-gray-500 md:top-1/2 md:text-gray-500 md:hover:bg-black md:hover:bg-opacity-40 md:hover:text-white"
          onClick={() => {
            if (imageId < displayedRoom[roomId].images.length - 1) {
              setImageId((prev) => prev + 1);
            }
          }}
        >
          &#10095;
        </button>

        <div
          id="thumbnail"
          className="m-auto hidden h-40 w-fit max-w-2xl text-center md:flex"
        >
          {displayedRoom[roomId].images.length > 0 &&
            displayedRoom[roomId].images.map((photo, index) => (
              <img
                onClick={() => setImageId(index)}
                className={`m-4 h-20 w-20 ${index !== imageId && "opacity-60"}`}
                src={photo}
                alt="thumbnails"
              />
            ))}
        </div>
      </div>
    );
  }
  return (
    <div className="w-full overflow-x-hidden text-center">
      {/* Top Gallery */}

      <div className="relative">
        <div className="md:grid md:grid-cols-4">
          <div className="relative ml-auto mr-auto hidden h-60 w-full md:block md:h-96">
            <div className="absolute bottom-0 left-0 right-0 top-0 bg-black opacity-45"></div>
            <img
              className="h-full"
              src={`${process.env.PUBLIC_URL}${displayedRoom[leftImageId].hero_image}`}
              alt=""
            />
          </div>
          <img
            className="ml-auto mr-auto h-60 md:col-span-2 md:block md:h-96 md:w-full"
            src={`${process.env.PUBLIC_URL}${displayedRoom[roomId].hero_image}`}
            alt=""
          />
          <div className="relative ml-auto mr-auto hidden h-60 w-full md:block md:h-96">
            <div className="absolute bottom-0 left-0 right-0 top-0 bg-black opacity-45"></div>
            <img
              className="h-full"
              src={`${process.env.PUBLIC_URL}${displayedRoom[rightImageId].hero_image}`}
              alt=""
            />
          </div>
        </div>

        <div className="absolute -bottom-0.5 grid w-screen grid-cols-2 p-4 text-white">
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-black opacity-45"></div>
          <div className="relative align-middle text-2xl">
            {displayedRoom[roomId].name}
          </div>
          <div className="relative text-2xl">
            Kshs. {displayedRoom[roomId].price}/night
          </div>
        </div>
        <button
          className="absolute left-0 top-20 w-auto cursor-pointer select-none p-4 text-lg font-bold text-white hover:text-white md:left-28 md:top-36 md:text-4xl md:hover:bg-black md:hover:bg-opacity-40"
          onClick={() => {
            if (roomId > 0) {
              setLeftImageId((prev) => {
                if (prev === 0) {
                  return rooms.length - 1;
                } else {
                  return prev - 1;
                }
              });
              setRightImageId((prev) => {
                if (prev > 0) {
                  return prev - 1;
                } else {
                  return rooms.length - 1;
                }
              });
              setRoomId((prev) => prev - 1);
            } else {
              setLeftImageId(rooms.length - 2);
              setRoomId(rooms.length - 1);
              setRightImageId(0);
            }
            setImageId(0);
          }}
        >
          &#10094;
        </button>
        <button
          className="absolute right-0 top-20 w-auto cursor-pointer select-none p-4 text-lg font-bold text-white hover:text-white md:right-28 md:top-36 md:text-4xl md:hover:bg-black md:hover:bg-opacity-40"
          onClick={() => {
            if (roomId < rooms.length - 1) {
              setLeftImageId((prev) => {
                if (prev === rooms.length - 1) {
                  return 0;
                } else {
                  return prev + 1;
                }
              });
              setRightImageId((prev) => {
                if (prev < rooms.length - 1) {
                  return prev + 1;
                } else {
                  return 0;
                }
              });
              setRoomId((prev) => prev + 1);
            } else {
              setLeftImageId(rooms.length - 1);
              setRightImageId(1);
              setRoomId(0);
            }
            setImageId(0);
          }}
        >
          &#10095;
        </button>
      </div>

      {/* Description Section */}

      <div className="appliances"></div>
      <div className="p-4 text-center text-4xl font-semibold">
        About {`${displayedRoom[roomId].name}`}
      </div>
      <div className="p-3 text-gray-500">
        <div className="icon"></div>
        <div className="text">{displayedRoom[roomId].descriptions[0]}</div>
      </div>
      <div className="p-3 text-gray-500">
        <div className="icon"></div>
        <div className="text">{displayedRoom[roomId].descriptions[1]}</div>
      </div>
      <div className="p-3 text-gray-500">
        <div className="icon"></div>
        <div className="text">{displayedRoom[roomId].descriptions[2]}</div>
      </div>

      {/* Bottom Section */}

      <div className="text-center">
        <div className="ml-auto mr-auto grid grid-cols-2 p-6">
          <p className="font-semibold">Images</p>
          <button
            className="text-blue-600"
            onClick={() => {
              setShowImages(true);
              setShowFooter("hidden");
            }}
          >
            See More
          </button>
        </div>
        <div className="grid grid-cols-3 gap-4 p-4">
          <img
            className="h-40 rounded md:h-96 md:w-96"
            onClick={() => {
              setShowImages(true);
              setImageId(0);
            }}
            src={`${process.env.PUBLIC_URL}${displayedRoom[roomId].images[0]}`}
            alt=""
          />
          <img
            className="h-40 rounded md:h-96 md:w-96"
            onClick={() => {
              setShowImages(true);
              setImageId(1);
            }}
            src={`${process.env.PUBLIC_URL}${displayedRoom[roomId].images[1]}`}
            alt=""
          />
          <img
            className="h-40 rounded md:h-96 md:w-96"
            onClick={() => {
              setShowImages(true);
              setImageId(2);
            }}
            src={`${process.env.PUBLIC_URL}${displayedRoom[roomId].images[2]}`}
            alt=""
          />
        </div>
        <div className="p-8 text-center">
          <a
            className="rounded-3xl bg-blue-800 p-4 text-white"
            href={`tel:254718 192 635`}
          >
            Contact To Book
          </a>
        </div>
      </div>
    </div>
  );
}
