import { Outlet } from "react-router-dom";
import { useState } from "react";

export default function Layout({ showFooter, setShowFooter }) {
  const [showSidebar, setShowSidebar] = useState("hidden");
  return (
    <div className="relative">
      <div className="fixed left-9 top-4 z-40">
        <button
          className="group relative"
          onClick={() => {
            if (showSidebar === "flex") {
              setShowSidebar("hidden");
            } else {
              setShowSidebar("flex");
            }
          }}
        >
          <div className="relative flex h-[50px] w-[50px] transform items-center justify-center overflow-hidden rounded-full bg-slate-700 shadow-md ring-0 ring-gray-300 ring-opacity-30 transition-all duration-200 hover:ring-8 group-focus:ring-4">
            <div className="flex h-[20px] w-[20px] origin-center transform flex-col justify-between overflow-hidden transition-all duration-300">
              <div
                className={`h-[2px] w-7 origin-left transform bg-white transition-all duration-300 ${
                  showSidebar === "flex" ? "rotate-[42deg]" : ""
                }`}
              ></div>
              <div
                className={`h-[2px] w-1/2 transform rounded bg-white transition-all duration-300 ${
                  showSidebar === "flex" ? "-translate-x-10" : ""
                }`}
              ></div>
              <div
                className={`h-[2px] w-7 origin-left transform bg-white transition-all duration-300 ${
                  showSidebar === "flex" ? "-rotate-[42deg]" : ""
                }`}
              ></div>
            </div>
          </div>
        </button>
      </div>

      <div className={`${showSidebar} fixed z-20`}>
        <div className="flex min-h-screen flex-row bg-gray-100">
          <div className="flex w-56 flex-col overflow-hidden rounded-r-3xl bg-white">
            <div className="flex h-20 items-center justify-center shadow-md"></div>
            <ul className="flex flex-col py-4">
              <li>
                <a
                  href="/"
                  className="flex h-12 transform flex-row items-center text-gray-500 transition-transform duration-200 ease-in hover:translate-x-2 hover:text-gray-800"
                >
                  <span className="inline-flex h-12 w-12 items-center justify-center text-lg text-gray-400">
                    <i className="bx bx-home"></i>
                  </span>
                  <span className="text-sm font-medium">Home</span>
                </a>
              </li>
              <li>
                <a
                  href="/accomodation"
                  className="flex h-12 transform flex-row items-center text-gray-500 transition-transform duration-200 ease-in hover:translate-x-2 hover:text-gray-800"
                >
                  <span className="inline-flex h-12 w-12 items-center justify-center text-lg text-gray-400">
                    <i className="bx bx-hotel"></i>
                  </span>
                  <span className="text-sm font-medium">Rooms</span>
                </a>
              </li>
              <li>
                <a
                  href="/restaurant"
                  className="flex h-12 transform flex-row items-center text-gray-500 transition-transform duration-200 ease-in hover:translate-x-2 hover:text-gray-800"
                >
                  <span className="inline-flex h-12 w-12 items-center justify-center text-lg text-gray-400">
                    <i className="bx bx-drink"></i>
                  </span>
                  <span className="text-sm font-medium">Restaurant</span>
                </a>
              </li>
              <li>
                <a
                  href="/conference"
                  className="flex h-12 transform flex-row items-center text-gray-500 transition-transform duration-200 ease-in hover:translate-x-2 hover:text-gray-800"
                >
                  <span className="inline-flex h-12 w-12 items-center justify-center text-lg text-gray-400">
                    <i className="bx bxs-business"></i>
                  </span>
                  <span className="text-sm font-medium">Conference</span>
                </a>
              </li>
              <li>
                <a
                  href="/gallery"
                  className="flex h-12 transform flex-row items-center text-gray-500 transition-transform duration-200 ease-in hover:translate-x-2 hover:text-gray-800"
                >
                  <span className="inline-flex h-12 w-12 items-center justify-center text-lg text-gray-400">
                    <i className="bx bxs-photo-album"></i>
                  </span>
                  <span className="text-sm font-medium">Gallery</span>
                </a>
              </li>
              <li>
                <a
                  href="/#reviews"
                  className="flex h-12 transform flex-row items-center text-gray-500 transition-transform duration-200 ease-in hover:translate-x-2 hover:text-gray-800"
                >
                  <span className="inline-flex h-12 w-12 items-center justify-center text-lg text-gray-400">
                    <i className="bx bxs-star"></i>
                  </span>
                  <span className="text-sm font-medium">Reviews</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
      <footer id="footer" className={`${showFooter}`}>
        <div className="h-fit bg-green-950 text-white">
          <div className="h-56 text-center">
            <div
              className="pt-10 font-serif font-bold"
              style={{ fontSize: "24px" }}
            >
              KELVIAN HOTEL
            </div>
            <div>Luxury | Business | Serenity</div>
            <div className="pt-16" style={{ fontSize: "52px" }}>
              Stay In Touch
            </div>
          </div>
          <div className="grid gap-8 p-10 md:grid-cols-2">
            <div className="ml-auto mr-auto text-center">
              <a href={`tel:254718 192 635`}>Telephone: (+254) 718 192 635</a>
              <br />
              <a href={`mailto:reservations@kelvianhotel.co.ke`}>
                Email: reservations@kelvianhotel.co.ke
              </a>
            </div>
            <div className="ml-auto mr-auto text-center">
              <a
                href={`https://play.google.com/store/apps/details?id=com.patakitu.patakitumobileapp`}
              >
                Designed & Powered By: <b>PataKitu</b>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
