import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
const sections = require("../sections_data.json");

export default function HomePage() {
  return (
    <div className="w-full overflow-x-hidden text-white">
      <HeroSection />
      {/* <IntroductionSection /> */}
      <div id="suites"></div>
      <IntroductionSectionTwo />

      <ServicesSection
        title={sections[0].title}
        description={sections[0].description}
        btnText={sections[0].button_text}
        btnLink={`/accomodation`}
        bgImg={"/assets/room.webp"}
      />
      <ServicesSection
        title={sections[1].title}
        description={sections[1].description}
        btnText={sections[1].button_text}
        btnLink={"/restaurant"}
        bgImg={"/assets/wine_and_dine.webp"}
      />
      <ServicesSection
        title={sections[2].title}
        description={sections[2].description}
        btnText={sections[2].button_text}
        btnLink={"/conference"}
        bgImg={"/assets/conference.webp"}
      />
      <ServicesSection
        title={sections[3].title}
        description={sections[3].description}
        btnText={sections[3].button_text}
        btnLink={"/gallery"}
        bgImg={"/assets/gallery.webp"}
      />
      <PartnersSection />
      <ReviewSection />
      <MapSection />
    </div>
  );
}

function HeroSection() {
  return (
    <div
      className={`relative h-screen w-screen overflow-hidden bg-cover bg-fixed bg-center bg-no-repeat`}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/hotel_hero.webp)`,
      }}
    >
      {/* <div className="absolute bottom-0 left-0 right-0 top-0 bg-black opacity-45"></div> */}
      <div className="relative text-center">
        <div className="md:w-50 p-4 md:mt-8" style={{ fontSize: "68px" }}>
          <div className="mt-28 font-serif font-bold">KELVIAN HOTEL</div>
          <div
            style={{
              fontSize: "24px",
            }}
            className=""
          >
            Luxury | Business | Serenity
          </div>
        </div>
        <a className="bg-amber-700 p-4" href="#suites">
          EXPLORE BELOW
        </a>
      </div>
    </div>
  );
}

/* function IntroductionSection(){
    return(
        <div className="bg-green-950 grid gap-8 h-fit md:gap-2 md:grid-cols-2 p-4">
            <div>
                <h1 className="text-4xl w-72">Timeless Suites, Delightful Stays</h1>
                <p className="w-72" >Experience fresh elegance with a dash of old-world charm in our newly-renovated heritage suites. </p>
            </div>
            <div className="grid gap-2 md:grid-cols-2 md:gap-2">
                <img className="h-64 ml-32 w-48 md:ml-0 md:mt-60 md:w-full object-cover" src={`${process.env.PUBLIC_URL}/assets/mini_intro_image_two.jpg`} alt="Mini" />
                <img className="object-cover w-full" src={`${process.env.PUBLIC_URL}/assets/dinner_presentation.jpg`} alt="Normal"/>
            </div>
            
            
        </div>
    )
    
} */

function IntroductionSectionTwo() {
  return (
    <div
      className={`relative grid h-screen bg-cover bg-center bg-no-repeat text-center`}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/gallery.webp)`,
      }}
    >
      {/* <div className="absolute bottom-0 left-0 right-0 top-0 bg-black opacity-45"></div> */}
      <div className="relative mb-auto mt-auto">
        <h2 style={{ fontSize: "52px" }}>
          {"Timeless Suites, Delightful Stays"}
        </h2>
        <p
          className="p-4 md:ml-auto md:mr-auto md:w-96"
          style={{ fontSize: "18px", fontWeight: "bolder" }}
        >
          {
            "Experience fresh elegance with a dash of exotic charm in our heritage suites."
          }
        </p>
      </div>
    </div>
  );
}

function ServicesSection({ title, description, btnText, btnLink, bgImg }) {
  return (
    <div
      className={`relative grid h-screen bg-cover bg-center bg-no-repeat text-center`}
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}${bgImg})` }}
    >
      {/* <div className="absolute bottom-0 left-0 right-0 top-0 bg-black opacity-45"></div> */}
      <div className="relative mb-auto mt-auto">
        <h2 style={{ fontSize: "52px" }}>{title}</h2>
        <p
          className="p-4 md:ml-auto md:mr-auto md:w-96"
          style={{ fontSize: "18px", fontWeight: "bolder" }}
        >
          {description}
        </p>
        <a
          className="bg-amber-700 p-4"
          href={`${process.env.PUBLIC_URL}${btnLink}`}
        >
          {btnText}
        </a>
      </div>
    </div>
  );
}

function PartnersSection() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "ease-in-out",
  };
  return (
    <div className="h-full bg-green-950">
      <div className="p-4 text-center font-sans" style={{ fontSize: "56px" }}>
        Partners
      </div>
      <Slider {...settings}>
        <img
          className="h-96 p-4"
          src={`${process.env.PUBLIC_URL}/assets/redcross_kenya.png`}
          alt="patakitu logo"
        />
        <img
          className="h-96 p-4"
          src={`${process.env.PUBLIC_URL}/assets/patakitu_partner.png`}
          alt="patakitu logo"
        />
        <img
          className="h-96 p-4"
          src={`${process.env.PUBLIC_URL}/assets/world_vision.png`}
          alt="patakitu logo"
        />
        <img
          className="h-96 p-4"
          src={`${process.env.PUBLIC_URL}/assets/Amref-Logo.webp`}
          alt="patakitu logo"
        />
      </Slider>
    </div>
  );
}

function ReviewSection() {
  return (
    <div className="h-full bg-green-950 md:h-1/2">
      <div
        id="reviews"
        className="p-4 text-center font-sans"
        style={{ fontSize: "56px" }}
      >
        Reviews
      </div>
      <div className="p-4">
        <iframe
          title="Reviews"
          src="https://widgets.sociablekit.com/google-reviews/iframe/25427829"
          width="100%"
          height="1000"
        ></iframe>
      </div>
    </div>
  );
}

function MapSection() {
  return (
    <div className="h-full bg-stone-500">
      <div className="p-4 text-center font-sans" style={{ fontSize: "56px" }}>
        Location
      </div>
      <iframe
        title="Map"
        className="h-80 w-full p-4"
        src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=%20+(Kelvian%20Hotel)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>
    </div>
  );
}
