const imageList = require("../conference_data.json");

export default function ConferencePage() {
  return (
    <div className="text-white">
      <HeroSection />
      <MainConference />
      <ExecutiveConference />
      <MediumConference />
      {/* <Expectations /> */}
      <PreviousEvents />
    </div>
  );
}

function HeroSection() {
  return (
    <div
      className="relative h-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}${imageList[0]})`,
      }}
    >
      <div className="absolute bottom-0 left-0 right-0 top-0 bg-black opacity-45"></div>

      <div className="relative pl-10 pt-64 text-3xl md:text-5xl">
        Conference, Banqueting & Events
      </div>
      <div className="relative pl-10 pt-5 text-lg md:text-3xl">
        The best facilities in Makueni County
      </div>
      <div className="relative p-8 text-center">
        <a
          className="p-4"
          style={{ backgroundColor: "#988778" }}
          href="#footer"
        >
          BOOK HALL
        </a>
      </div>
    </div>
  );
}

function MainConference() {
  return (
    <div className="h-fit bg-green-950">
      <div className="md:grid md:grid-cols-2">
        <div>
          <div className="p-4 text-center" style={{ fontSize: "43px" }}>
            MAIN CONFERENCE HALL
          </div>
          <div
            className="p-4 pt-14"
            style={{ fontFamily: "Georgia", fontSize: "25px" }}
          >
            A versatile space equipped with modern amenities to meet the needs
            of various events. It is designed to comfortably accommodate up to
            250 attendees, making it ideal for conferences, seminars, and large
            meetings. The state-of-the-art facilities ensure a seamless
            experience for both presenters and participants. Whether for
            business or social gatherings, this hall provides a professional and
            inviting environment
          </div>
        </div>
        <div className="p-4">
          <img
            className="h-52 w-80 rounded md:h-full md:w-full"
            src={`${process.env.PUBLIC_URL}${imageList[1]}`}
            alt="conference"
          />
        </div>
      </div>
      <hr className="ml-auto mr-auto mt-10 w-3/4 p-4" />
    </div>
  );
}

function ExecutiveConference() {
  return (
    <div className="h-fit bg-green-950">
      <div className="grid grid-cols-1 md:grid md:grid-cols-2">
        <div className="order-2 p-4 md:order-1">
          <img
            className="h-52 w-80 rounded md:h-full md:w-full"
            src={`${process.env.PUBLIC_URL}${imageList[2]}`}
            alt="conference"
          />
        </div>
        <div className="order-1 md:order-2">
          <div className="p-4 text-center" style={{ fontSize: "43px" }}>
            EXECUTIVE BOARDROOM
          </div>
          <div
            className="p-4 pt-14"
            style={{ fontFamily: "Georgia", fontSize: "25px" }}
          >
            An ideal space for smaller, more intimate gatherings. Designed to
            accommodate up to 10 individuals, it provides a perfect setting for
            focused discussions and strategic meetings. The room is equipped
            with all the necessary amenities to ensure a productive and
            efficient environment. Whether for executive meetings or private
            consultations, this boardroom offers a professional and comfortable
            atmosphere.
          </div>
        </div>
      </div>
      <hr className="ml-auto mr-auto mt-10 w-3/4 p-4" />
    </div>
  );
}

function MediumConference() {
  return (
    <div className="h-fit bg-green-950">
      <div className="md:grid md:grid-cols-2">
        <div>
          <div className="p-4 text-center" style={{ fontSize: "43px" }}>
            MEDIUM BOARDROOM
          </div>
          <div
            className="p-4 pt-14"
            style={{ fontFamily: "Georgia", fontSize: "25px" }}
          >
            Designed to host groups of up to 50 people, providing a suitable
            space for mid-sized meetings and events. This executive boardroom
            offers a balance between capacity and intimacy, making it ideal for
            workshops, presentations, and collaborative sessions. Equipped with
            modern facilities, it ensures a productive and efficient meeting
            environment. Whether for business or professional gatherings, this
            boardroom delivers a professional and comfortable setting.
          </div>
        </div>
        <div className="p-4">
          <img
            className="h-52 w-80 rounded md:h-full md:w-full"
            src={`${process.env.PUBLIC_URL}${imageList[3]}`}
            alt="conference"
          />
        </div>
      </div>
      <hr className="ml-auto mr-auto mt-10 w-3/4 p-4" />
    </div>
  );
}

function PreviousEvents() {
  return (
    <div className="h-fit bg-green-950">
      <div className="p-4 text-center" style={{ fontSize: "43px" }}>
        Previous Events
      </div>
      <div
        className="md:grid md:grid-cols-3 md:gap-8"
        style={{ fontFamily: "Georgia" }}
      >
        <div className="mt-8 text-center">
          <img
            className="ml-auto mr-auto h-56 w-56 rounded"
            src={`${process.env.PUBLIC_URL}${imageList[4]}`}
            alt="item"
          />
          <h3 className="p-4 text-2xl font-extrabold">
            Engaging and high-quality articles for your website
          </h3>
          <p className="p-6">
            News write-ups offer a great way to let clients know about new
            products and services, events, awards, and more!News write-ups offer
            a great way to let clients know about new products and services,
            events, awards, and more!
          </p>
        </div>
        <div className="mt-8 text-center">
          <img
            className="ml-auto mr-auto h-56 w-56 rounded"
            src={`${process.env.PUBLIC_URL}${imageList[4]}`}
            alt="item"
          />
          <h3 className="p-4 text-2xl font-extrabold">
            Engaging and high-quality articles for your website
          </h3>
          <p className="p-6">
            News write-ups offer a great way to let clients know about new
            products and services, events, awards, and more!News write-ups offer
            a great way to let clients know about new products and services,
            events, awards, and more!
          </p>
        </div>
        <div className="mt-8 text-center">
          <img
            className="ml-auto mr-auto h-56 w-56 rounded"
            src={`${process.env.PUBLIC_URL}${imageList[4]}`}
            alt="item"
          />
          <h3 className="p-4 text-2xl font-extrabold">
            Engaging and high-quality articles for your website
          </h3>
          <p className="p-6">
            News write-ups offer a great way to let clients know about new
            products and services, events, awards, and more!News write-ups offer
            a great way to let clients know about new products and services,
            events, awards, and more!
          </p>
        </div>
      </div>
    </div>
  );
}
