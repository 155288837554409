import "./App.css";

import { Route, Routes, BrowserRouter } from "react-router-dom";
import ConferencePage from "./pages/ConferencePage";
import GalleryPage from "./pages/GalleryPage";
import HomePage from "./pages/HomePage";
import RestaurantPage from "./pages/RestaurantPage";
import RoomBookingPage from "./pages/RoomBookingPage";
import Layout from "./Layout";
import { useState } from "react";

function App() {
  const [showFooter, setShowFooter] = useState("block");
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout showFooter={showFooter} setShowFooter={setShowFooter} />
          }
        >
          <Route index element={<HomePage />} />
          <Route
            path="/accomodation"
            element={<RoomBookingPage setShowFooter={setShowFooter} />}
          />
          <Route path="/conference" element={<ConferencePage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/restaurant" element={<RestaurantPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
